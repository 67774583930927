import { OrderTableOptions } from 'components/orders/OrdersTable/OrdersTable';
import { Order, nbCards } from 'components/orders/types';
import { get, post, put, patch, del } from '../api';

export const getOrders = (
  status: string,
  filter: OrderTableOptions,
): Promise<{ orders: Order[]; totalCount: number }> => {
  filter.query = transformQuery(filter.query);
  return get('/orders', {}, { status, ...filter });
};

export const getOrdersByMachineId = (status: string, machineId: string): Promise<Order[]> =>
  get('/orders/machine/:machineId', { machineId }, { status });

const dateRegex = () => /^\d{4}-\d{2}-\d{2}$/gim;

export function transformQuery(query: string): string {
  const terms = query.replace('|', '').split(/\s+/gi);
  for (let i = 0; i < terms.length; i++) {
    const term = terms[i];
    if (dateRegex().test(term)) {
      terms[i] = new Date(`${term}T00:00:00`).toISOString();
    } else {
      const parts = term.split('@');
      if (parts.length === 2 && dateRegex().test(parts[0]) && dateRegex().test(parts[1])) {
        terms[i] = `${new Date(`${parts[0]}T00:00:00`).toISOString()}@${new Date(
          `${parts[1]}T23:59:59.999`,
        ).toISOString()}`;
      }
    }
  }

  return terms.join(' ');
}

export const updateOrdersInclusions = () => post('/orders/updateOrdersInclusions');

export const updateUnprocess = () => post('/orders/updateUnProcess');

export const createOrder = (order: Order) => post('/orders', {}, order);

export const processCubiOrders = (orgId: string, barcodes: string[], cubiUser?: string) =>
  post('/orders/:orgId/processCubiOrders', { orgId }, { barcodes, cubiUser });

export const reloadCubiOrder = (orderId: string) =>
  post('/orders/:orderId/reloadCubiOrder', { orderId });

export const updateOrder = (id: string, order: Order) => put('/orders/:id', { id }, order);

export const triggerOrder = (id: string, machineId: string) =>
  post('/orders/:id/trigger/:machineId', { id, machineId });

export const manuallyExcludeDrug = (id: string, drugDIN: string) =>
  post('/orders/:id/drugs/:drugDIN/manuallyExclude', { id, drugDIN });

export const manuallyCompleteOrder = (id: string) =>
  post('/orders/:id/manuallyCompleteOrder', { id });

export const manuallyCompletePendingOrder = (id: string) =>
  post('/orders/:id/manuallyCompletePendingOrder', { id });

export const includeDrug = (id: string, drugDIN: string) =>
  post('/orders/:id/drugs/:drugDIN/include', { id, drugDIN });

export const toggleManuallyDistributedDrug = (id: string, drugDIN: string) =>
  post('/orders/:id/drugs/:drugDIN/toggleManuallyDistributed', { id, drugDIN });

export const toggleFlaggedDrug = (id: string, drugDIN: string, message: string) =>
  post('/orders/:id/drugs/:drugDIN/toggleFlagged', { id, drugDIN }, { message });

export const patchOrder = (id: string, order: Partial<Order>) =>
  patch('/orders/:id', { id }, order);

export const cancelOngoing = (id: string) => post('/orders/:id/cancel', { id });

export const deleteOrder = (id: string, order: Order) => del('/orders/:id', { id, order });

export const archiveOrder = (id: string) => put('/orders/archive/:id', { id });

export const sendCompletedMessage = (id: string) =>
  post('/orders/:id/sendCompletedMessage', { id });

export const restoreOrder = (id: string) => post('/orders/:id/restore', { id });

export const changeDrug = (id: string, currentDIN: string, alternativeDIN: string) =>
  post('/orders/:id/changeDrug', { id }, { currentDIN, alternativeDIN });

export const changeNbCards = (id: string, nbCards: nbCards, barcodes?: string[]) =>
  patch('/orders/:id/changeNbCards', { id }, { nbCards, barcodes });

export const getTrayOrder = (trayId: string): Promise<{ order: Order | null }> =>
  get('/orders/tray/:trayId', { trayId });

export const getIntraysWithIncludedDrug = (bucketId: string) =>
  get('/orders/tray/bucket/:bucketId', { bucketId });

export const distributeManuallyInclusionsIntray = (id: string, bucketDIN: string) =>
  post('/orders/:id/distributeManuallyInclusionsIntray', { id }, { bucketDIN });

export const cancelManualDistributionIntray = (id: string, bucketDIN: string) =>
  post('/orders/:id/cancelManualDistributionIntray', { id }, { bucketDIN });

export const verifyQuantityInTrays = (id: string) =>
  post('/orders/:id/verifyQuantityInTrays', { id });

export const finishTray = (id: string, trayId: string) =>
  post('/orders/:id/finishTray', { id }, { trayId });

export const removeTray = (id: string, trayId: string) =>
  post('/orders/:id/removeTray', { id }, { trayId });

export const addTray = (id: string, trayId: string, barcodes?: string[]) =>
  post('/orders/:id/addTray', { id }, { trayId, barcodes });

export const replaceTray = (id: string, trayToRemoveId: string, trayToAddId: string) =>
  post('/orders/:id/replaceTray', { id }, { trayToRemoveId, trayToAddId });
