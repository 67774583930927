const en = {
  actions: 'Actions',
  activate: 'Activate',
  active: 'Active',
  ACTIVE: 'ACTIVE',
  actual: 'actual',
  actualQuantity: 'Current quantity :',
  additionalCause: 'Cause (additionnal)',
  addAdmin: 'Add an administrator',
  addAlternative: 'Add an alternative',
  addDrug: 'Add a drug',
  addLot: 'Add a lot',
  addMachine: 'Add a machine',
  addOrganization: 'Add an organization',
  addToScheduled: 'Add to refill list',
  addTray: 'Add a tray',
  addTray_4cards: 'Add a tray (two (2) trays makes four (4) blister cards).',
  addUser: 'Add a user',
  administrators: 'Administrators',
  all: 'All',
  allOrg: 'All organizations',
  alreadyLinked: 'This tray is already linked!',
  alreadyOngoing:
    'You want to send the order #{{sendingOrderID}} of {{sendingOrderPatient}}, but the order #{{ongoingOrderID}} of {{ongoingOrderPatient}} seems to be ongoing. What is the conclusion of order #{{ongoingOrderID}} ?',
  altAvailable: 'Alternative available',
  altDrugInfos: '{{altName}} (DIN {{altDIN}})',
  alternatives: 'Alternatives',
  currentDrugInfos: 'is an alternative for {{currentName}} (DIN {{currentDIN}}).',
  altModalOrderInfo: 'Do you want to edit the order of {{patient}} made on {{createdAt}} ?',
  appInMaintenance: 'Your application is under maintenance.',
  archive: 'Archive',
  archiveDelay: 'Archive delay',
  archiveDelayTwoWeeks: '2 weeks',
  archiveDelayOneMonths: '1 month',
  archiveDelayThreeMonths: '3 months',
  archiveDelaySixMonth: '6 months',
  archiveDelayOneYear: '1 year',
  archived: 'Archived',
  average: 'Average',
  authorizationRequired: 'Authorization required',
  BACKORDER: 'BACK ORDER',
  BACK_ORDER: 'Back order',
  backOrdersAndOrdered: 'On back order or ordered',
  barcodes: 'Multi-card order barcodes',
  barcodeRequired: 'Barcode required',
  barcodeMustBe8Char: 'The barcode must be 8 charaters.',
  barcodeYieldNoOrder: 'The barcode {{barcode}} do not match any order.',
  bestSellers: 'Best-Sellers',
  bucket: 'Bucket',
  buckets: 'Buckets',
  bucketDetails: 'Bucket #{{position}}: {{DIN}} - {{drugName}} ({{drugForce}})',
  bucketNotFound: 'The bucket could not be found.',
  bucketsNotUsed: 'Buckets not used',
  BucketPositionDoesNotMatch: 'The bucket number does not match the selected bucket.',
  cancel: 'Cancel',
  cancelDistributionOf: 'Cancel distribution of {{drug}} {{force}}',
  capacity: 'Capacity',
  cards: 'Cards',
  cause: 'Cause',
  causeText: 'Cause (text)',
  unlinkFromTray: 'Unlink order from tray',
  unlinkFromTrayAndContinue: 'unlink the tray and continue',
  chooseJSONfile: 'Please choose a JSON file.',
  chooseOrganization: 'Please choose an organization.',
  clickHereToRefresh: 'Click here to refresh the page',
  close: 'Close',
  complete: 'Complete',
  completed: 'completed',
  completed_One: 'Completed',
  completed_Other: 'Completed',
  completeDistributionOf: 'Complete distribution of {{drug}} {{force}}',
  completedOn: 'Completed on',
  completeAsXCards: 'Complete as {{nbCards}} cards',
  completeManually: 'Complete manually',
  compatible2FAApps: 'Compatible 2FA apps :',
  confirm: 'Confirm',
  confirmImport: 'Confirm import',
  confirmImportStatement: 'Would you like to import for :',
  confirmation: 'Confirmation',
  confirmationRequired: 'Confirmation required',
  confirmPassword: 'Confirm Password',
  confirmPasswordRequired: 'Confirm password is required.',
  confirmSend: 'Confirm and send',
  confirmUnassignTray: 'Confirm tray unassignment',
  conflictualDistribution: "Conflictual distribution detected, please check the patient's record.",
  contactChronomedQuestion: 'Please contact your Chronomed representative for more informations.',
  continuePrintOldOrder: 'Do you wish to send the order #{{lookupId}} made on {{createdAt}}?',
  continueWith: 'Do you want to continue with the order #{{lookupId}}?',
  cubiBarcodeAlreadyInActiveOrder:
    'Please archive the order #{{lookupId}} to import the barcode {{barcode}}.',
  cubiOrderNotFound: 'Cubi order(s) not found',
  date: 'Date',
  dashboard: 'Dashboard',
  dailyUnload: 'Bucket filling',
  deactivate: 'Deactivate',
  deactivatedBuckets: 'deactivated buckets',
  deactivateBucket: 'Deactivate bucket #{{position}} {{drugName}} (DIN {{DIN}})',
  definitiveOperation: 'This operation is definitive !',
  delete: 'Delete',
  deleteDrugConfirmation: 'Are you sure you want to delete this drug?',
  deleteMachineConfirmation: 'Are you sure you want to delete the machine?',
  deleteMachineOrderAlert: '{{count}} {{status}} order(s)',
  deleteOrganizationConfirmation: 'Are you sure you want to delete the organization?',
  deleteOrganizationMachinesWarning: 'The following machine(s) will be removed.',
  deleteUserConfirmation: 'Are you sure you want to proceed?',
  deleteUserMessage: 'This user will be permanently deleted.',
  deprecatedDINs: 'Deprecated DINs',
  deprecatedDINWarning: 'This DIN is deprecated',
  deselectAll: 'Deselect all',
  detectedChanges: 'Detected changes',
  DIN: 'DIN',
  dinAlreadyExist: 'DIN already exists.',
  DINDoesNotMatch: 'The DIN does not match the selected bucket.',
  DINMustBe8charsLong: 'The DIN must be 8 characters long.',
  DINRequired: 'DIN is required.',
  doNotEdit: 'Do not edit',
  drug: 'Drug',
  drugsExpired: 'Expired drug(s)',
  drugExpireSoon: 'The lots of {{nb}} bucket(s) will soon be expired (1 month).',
  drugSkipped: 'Drug skipped',
  drugs: 'Drugs',
  EANWarning: '13-digits UPC (EAN).',
  EXCHANGE: 'Exchange',
  EXCHANGECAP: 'EXCHANGE',
  edit: 'Edit',
  editBucket: 'Edit bucket #{{position}} : {{drugName}} - {{force}}',
  editDrug: 'Edit drug',
  editOrder: 'Edit the order',
  editOrganization: 'Edit organisation {{organizationName}}',
  editQuantity: 'Change Quantity of #{{position}} : {{DIN}} - {{drugName}}.',
  editTray: 'Edit Tray(s)',
  emailAddress: 'Email Address',
  emailAddressAlreadyTaken: 'This email address is already taken.',
  emailAddressRequired: 'Email address is required.',
  emptyAndUnload: 'Empty & Unload',
  emptyBucketBeforeRefill: 'Be sure to empty the bucket before refilling it.',
  enable2FA: 'Enable 2FA',
  encounterError: 'An error has occurred.',
  endDateRequired: 'End date is required.',
  endDateBeforeStart: 'Invalid end date.',
  enterAtLeastALot: 'You must enter at least a lot.',
  enterExpirationDate: 'Enter the expiration date.',
  enterLotNumber: 'Enter the lot number.',
  enterReason: 'Enter the reason :',
  enterQuantity: 'Enter the quantity.',
  enter2FACode: 'Enter the 2FA code',
  error: 'Error',
  error401: 'HTTP error 401',
  error404: 'HTTP error 404',
  error500: 'HTTP error 500',
  errorAtUploadCubiOrders: 'An error occur while importing Cubi orders.',
  errorImportDin: 'No DIN was given when importing the order.',
  errorFetchingData: 'Error fetching data',
  excluded: 'Excluded',
  excludedNotDistributed: 'Some excluded were not distributed.',
  expirationDate: 'Expiration Date',
  expireSoon: 'Expire soon',
  expiredDrugs: 'Expired drugs',
  expiredDrugsAlert: 'The lots of {{nb}} bucket(s) have expired.',
  expiredOn: 'Expired on {{expDate}}.',
  EXPIRED: 'EXPIRED',
  EXPIRED_DRUGS: 'Expired drugs',
  EXPIRINGSOON: 'EXPIRING SOON',
  exportPDF: 'Export PDF',
  extend: 'Extend',
  failed: 'Failed',
  failedOn: 'Failed On',
  fieldOnlyAcceptNumbers: 'This field only accept numbers.',
  fieldOnlyAcceptAlphanumeric: ' This field only accept alphanumeric characters.',
  fileRequired: 'File is required.',
  finish: 'Finish',
  fillingRequired: 'Filling required.',
  QtAvailable: 'Quantity available',
  firstName: 'First name',
  firstNameRequired: 'First name is required.',
  filterByCubiUser: 'Filter by Cubi user',
  flag: 'Flag',
  errorOccurred: 'An error has occurred',
  format: 'Format',
  fri: 'FRI',
  generate: 'Generate',
  general: 'General',
  generic: 'Generic',
  genericAvailable: 'A generic is available.',
  generateOrders: 'Generate orders',
  halfTrayNumber: 'Half tray number',
  hello: 'Hello',
  hideArchivedOrders: 'Hide archived orders',
  homePage: 'Home page',
  importBestSellers: 'Import Best-Sellers',
  importDrugList: 'Import Drug List',
  include: 'Include',
  included: 'Included',
  IncludedExcludedRatio: 'Included/Excluded ratio',
  includedExcludedInfos:
    'This statistic is calculated by taking the proportion of included and excluded drugs.',
  includedExcludedCalculus:
    'Included : Sum of the included drugs / number of drugs. \nExcluded : Sum of the excluded drugs / number of drugs.',
  incorrectDINFormat: 'Incorrect DIN format.',
  incorrectDINLength: 'Incorrect DIN length.',
  incorrectUPCFormat: 'Incorrect UPC format.',
  incorrectUPCLength: 'Incorrect UPC length.',
  invalidFormat: 'Invalid format.',
  invalidFormatName:
    'Invalid format for at least one name field. \n (Accepted characters are alphanumeric, accents, periods, parentheses, and the symbols + and -).',
  invalidFormatForce:
    'Invalid format for at least one force field. \n (Accepted characters are alphanumeric, periods, slashes, and the symbol +).',
  invalidForceField: 'This field only accept numbers, periods and commas.',
  invalidJSONFile: 'Invalid JSON file.',
  invalidEmail: 'Invalid email address.',
  invalidToken: 'Invalid 2FA code.',
  invalidSearchQuery: 'Invalid search.',
  invalidSymbolInText: 'The text contains invalid symbols.',
  invalidLabelFormat: 'Invalid label format.',
  invalidPasswordFormat:
    'Password must have at least one lowercase letter, one uppercase letter, a number, and a special character.',
  invalidUsername: 'Invalid username.',
  internalServerError: 'Internal server error',
  jsonContentError: 'There seems to be a problem with the contents of the JSON file.',
  justification: 'Justification',
  label: 'Label',
  labelDoesNotMatch: 'Label do not match selected bucket.',
  language: 'Language',
  last7Days: 'Last 7 days',
  lastName: 'Last name',
  lastNameRequired: 'Last name is required.',
  lastUpdatedOn: 'Last updated on',
  linkedOrderNoTray: "* The order #{{order}} of {{patient}} won't be link to any tray.",
  linkedOrderOneTray: '* The order #{{order}} of {{patient}} will have one tray.',
  linkingWithOrder:
    ' You wish to link the order #{{lookupId}} of {{patient}}, created on {{createdAt}}.',
  linkOrderToTray: 'Link the order to tray',
  linkToTray: 'Link to tray',
  linkOrdersNeedManualDistribution:
    'Warning! {{nbOrders}} linked orders will need a manual distribution.',
  listBucketToUnload: 'List of buckets to unload (DIN) :',
  load: 'Load',
  loadMultipleMessage:
    'You are about to load multiple buckets, to continue click the "Send" button.',
  login: 'Login',
  logout: 'Logout',
  lotExpiration: 'A lot of this bucket will expire on {{expDate}}.',
  lotNumber: 'Lot Number',
  LOWQUANTITY: 'LOW QUANTITY',
  manage: 'Manage',
  manageExpired: 'Manage expired lots',
  MAINTENANCE: 'Maintenance',
  MAINTENANCECAP: 'MAINTENANCE',
  machineAlreadyExist: 'The machine already exists.',
  machineDistribution: 'Machine distribution',
  machineNameRequired: 'Machine name is required.',
  machines: 'Machines',
  maxCapacity: 'Max capacity',
  maximumPasswordLength: 'Password must be a maximum of 64 characters long.',
  meanDurationUse: 'Mean duration of use',
  meanBucket: 'Mean nb of molecules/order',
  meanBucketByMonth: 'Mean nb of buckets/month',
  meanDailyUsage:
    'This statistic is calculated by taking the sum of the daily activity of the machine',
  meanDailyUsageCalculus: 'Mean daily usage = Sum of the daily activity of the machine',
  meanDailyUnload:
    'This statistic is calculated by taking the average of the daily bucket filling.',
  meanDailyUnloadCalculus: 'Mean daily unload = Sum of the daily bucket filling / number of days',
  meanDrug: 'Mean nb of drugs/order',
  meanDrugInfos: 'This statistic is calculated by taking the average of the drugs per order.',
  meanDrugCalculus: 'Mean drugs = Sum of the drugs / number of orders',
  meanDrugAndTotal: 'Mean number of drugs per patient/total number of drugs',
  meanDrugAndTotalAbr: 'Total/Mean',
  meanDrugByPatient: 'Average number of medications per patient"',
  meanIncludedDrugInfos:
    'This statistic is calculated by taking the average of the included drugs.',
  meanIncludedDrugCalculus: 'Mean included drugs = Sum of the included drugs / number of drugs',
  meanSpeed: 'Average speed/pill box',
  meanSpeedInfos:
    'This statistic is calculated by taking the average speed over the last 30 days of completed orders including only the 3 sigmas.',
  meanSpeedInfosCalculus:
    'Average Speed = Sum of the difference between the completion date and the order creation date / number of orders / 2',
  meanUsage: 'Usage time per day',
  minimumPasswordLength: 'Password must be at least 8 characters long.',
  mon: 'MON',
  monthlyQty: 'Monthly quantity',
  multipleLoading: 'Multiple loading',
  multipleUnloading: 'Multiple unloading',
  multipleUnloadBucket: 'Unload multiple buckets',
  multipleLoadBucket: 'Load multiple buckets',
  mustReconnect: 'You must reconnect.',
  name: 'Name',
  nameRequired: 'Name is required.',
  nbPatientsDaily: 'Nb patients/day',
  NbDeactivatedBucket: 'Nb of deactivated buckets',
  need10more: '*10 extra units required.',
  newDIN: 'New DIN',
  newestOrderExist:
    'A newest order exist for {{patient}} (order #{{lookupId}} made on {{createdAt}}).',
  newOrdersAvailable: '{{nb}} new orders(s) available.',
  next: 'Next',
  noDataToDisplay: 'No data to display.',
  noDINDrug: 'MISSING DIN',
  notEnoughtQtyForTrays:
    'The inventory of the following buckets is insufficent to procees all in trays orders and the order #{{lookupId}} of {{patient}}.',
  notValidatedDrug: 'Drug pending validation',
  notValidatedDrugCount: 'Not validated drugs ({{notValidatedDrugCount}})',
  noOrdersToDisplay: 'There are no orders to display.',
  noIncludedDrugs: 'No drugs in machine distribution.',
  numberTotalDrug: 'Total number of drugs :',
  of_sur: 'of',
  of_du: 'of',
  ok: 'OK',
  oldDIN: 'Old DIN',
  older2weeks: 'This order was created over two (2) weeks ago.',
  optionnal: ' (optional)',
  orEmailAddress: 'or email address',
  order: 'Order',
  orders: 'Orders',
  ORDEREDCAP: 'ORDERED',
  ORDERED: 'Ordered',
  organization: 'Organization',
  organizationAlreadyExist: 'The organization already exists.',
  organizationNameRequired: 'The organization name is required.',
  organizations: 'Organizations',
  OTHERCAP: 'OTHER',
  OTHER: 'Other',
  pageNotFound: 'Page not found',
  passedDate: 'The date must be in the future.',
  password: 'Password',
  passwordRequired: 'Password is required.',
  passwordMustMatch: 'The passwords do not match.',
  resetPasswordForUser: "{{userName}}'s Password Update",
  patient: 'Patient',
  PatientForThisMonth: 'Patients for this month',
  PatientPerDay: 'Patients per day',
  PatientPerMonth: 'Patients per month',
  pillBoxPerHour: 'pill box per hour',
  pillDistributionReport: 'Dispensed Medications Report',
  pleaseEnterDIN: 'Please enter a DIN.',
  pleaseEnterExpirationDate: 'Please enter an expiration date.',
  pleaseEnterLotNumber: 'Please enter a lot number.',
  pleaseEnterMessage: 'Please enter a message.',
  pleaseEnterQuantity: 'Please enter a quantity.',
  pleaseEnterJustification: 'Please enter a justification.',
  pleaseEnterTrayNumber: 'Please enter a tray number.',
  pleaseEnterUPC: 'Please enter a UPC.',
  pleaseReEnter2FACode: 'Please re-enter the 2FA code.',
  pleaseScanTheLabel: 'Please scan the chronomed bucket barcode.',
  pleaseScanTheCardLabel: 'Please scan the card barcode.',
  prescriptionSoftware: 'Prescription Software',
  previous: 'Previous',
  print: 'Print',
  printDate: 'Printed on',
  qtymonth: 'Qty/month',
  qtyToDistribute: 'Quantity to distribute : {{qty}}.',
  quantity: 'Quantity',
  quantityCantBeNegative: "The quantity can't be negative.",
  quantityMustBeAtLeast1: 'The quantity must be at least 1.',
  quantityMustBeMax5000: 'The quantity must be under 5000.',
  quantityMustBeNumber: 'The quantity must be a number.',
  quantityTotalMustBeMax5000: 'The total quantity must be under 5000.',
  reason: 'Reason',
  realQuantity: 'Real Quantity',
  recommendedQty: 'Recommended qty',
  refill: 'Refill',
  refillNow: 'Refill now',
  removeLot: 'Remove lot',
  replaceTray: 'Replace tray #{{tray}}',
  report: 'Report',
  reports: 'Reports',
  restore: 'Restore',
  returnToLogin: 'Return to login page',
  rememberThisDevice: 'Remember this device',
  removeFromList: 'Remove from refill list',
  removeDrugFromMachine: 'This drug will be remove from the machine distribution.',
  role: 'Role',
  sat: 'SAT',
  save: 'Save',
  scanTheBarcodeOnChronopacBucket: 'Scan the barcode on the Chronopac bucket.',
  scanTheBarcodeOnDrugContainer: 'Scan the barcode (DIN) of the drug container.',
  scanQrCodeInstructions: 'Scan the QR code with your authenticator app.',
  scanCubiBarcode: 'Scan the CUBI barcode',
  search: 'Search',
  seconds: 'Seconds',
  seeList: 'Go to list',
  selectAlternative: 'Select an alternative for ',
  selectFromList: 'Select from list',
  selectUser: 'Select a user',
  selectAll: 'Select all',
  selectAStat: 'Select a statistic',
  selectOrganization: 'Select an organization',
  selectedFormat: 'Selected format(s)',
  send: 'Send',
  sendBack: 'Send back',
  sendToVAssystSuccessful: 'Message sent to V-Assyst successfully!',
  sendToVAssystFailed: 'Failed to send message to V-Assyst.',
  sendVAssyst: 'To V-Assyst',
  setBucketQtyTo0: "Do you want to empty the bucket and set it's quantity to 0 ?",
  settings: 'Settings',
  sessionExpired: 'Your session expired.',
  sessionExpireSoon: 'Your session expire soon.\n {{time}}s.',
  skipToastMessage: 'The bucket #{{bucketPosition}} from order #{{lookupId}} has been skipped.',
  status: 'Status',
  start: 'Start',
  startDateAfterEnd: 'Invalid start date.',
  startDateRequired: 'Start date is required.',
  startedOn: 'Started on',
  strength: 'Strength',
  software: 'Software',
  submit: 'Submit',
  sun: 'SUN',
  switchOrdersInTray:
    '* The tray #{{tray}} will now be linked to {{patient}} (order #{{order}} created on {{createdAt}}).',
  switchToManualDistribution: 'Switch for manual distribution',
  switchDistributionInfo: 'You wish to manually distribute {{drug}} {{force}} (DIN {{DIN}}) ?',
  technicians: 'Technicians',
  thisMonth: 'This month',
  thisWeek: 'This week',
  toRefill: 'To Refill',
  TOREFILL: 'TO REFILL',
  totalDrug: 'Total of included molecules',
  tray: 'Tray',
  trayAlreadyAssigned:
    ' This tray is already linked to the order #{{orderAssigned}} of {{patientAssigned}} created on {{createdAt}}.',
  trayIdRequired: 'Tray number is required.',
  trayNumber: 'Tray number',
  tue: 'TUE',
  thu: 'THU',
  twoFA: 'Two-Factor Authentication',
  twoFASetup: 'Two-Factor Authentication Setup',
  twoFAEnabled:
    'Two-Factor Authentication Enabled, you will be asked to scan the QR code during the next login.',
  twoFADisabled: 'Two-Factor Authentication Disabled',
  unlink: 'Unlink',
  unassigned: 'Unassigned',
  unassignInfos:
    'You want to unassign tray #{{tray}} from order #{{order}} of {{patient}} created on {{createdAt}} ?',
  uniqueBarcodes: 'There are identical barcodes! Are you sure you want to continue?',
  unknownDrug: 'UNKNOWN DRUG',
  unknownDIN: 'No drugs known for this DIN',
  unload: 'Unload',
  unloading: 'Unloading',
  unregisteredFormat: 'Unregistered format.',
  unspecified: 'Unspecified',
  unvalidatedUPC: 'Unvalidated UPCs',
  updateList: 'Update list',
  updateListEraseInfos: 'Updating the list will erase the information currently being entered.',
  UPCArrayMissing: 'UPC list missing.',
  UPCDoesNotMatch: 'The UPC does not macth that of the selected drug.',
  UPCMustBeAtLeast8charsLong: 'The UPC must be at least 8 characters long.',
  UPCMustBeAtLeast12charsLong: 'The UPC must be at least 12 characters long.',
  UPCMustBeAtMost13charsLong: 'The UPC must be at most 13 characters long.',
  UPCRequired: 'UPC is required.',
  updateOrders: 'Update orders',
  updateUnprocess: 'Archive unprocessed orders',
  updateCubiOrder: 'Update from CUBI',
  userName: 'Username',
  userNameAlphanumericValidation: 'Username must only contain alphanumeric characters.',
  userNameAlreadyTaken: 'This username is already taken.',
  userNameRequired: 'Username is required.',
  user: 'User',
  userGuide: 'User guide',
  userCubiNotAuthorized: 'CUBI user not authorized.',
  userNotFound: 'User not found.',
  users: 'Users',
  validateDrug: 'Validate drug',
  validateNewUpc: 'Validate new UPCS ',
  vAssyst: 'V-Assyst',
  verify: 'Verify',
  value: 'Value',
  wantToEditOrderOf: 'Do you want to edit the Order of',
  warning: 'Warning !',
  weeks: 'Weeks',
  wen: 'WEN',
  warning2weeks: 'Attention, this order is older than 2 weeks.',
  warningDiscsCompatibility: 'Attention, please make sure the discs are compatible!',
  warningTrayWithExclusions:
    'Warning, the trays containing {{drugName}} {{drugForce}} (DIN {{drugDIN}}) require manual redistribution',
  whatDoYouWantToDo: 'What do you want to do ?',
  wholeQtyOnly: 'The quantity must be a whole number.',
  willLinkTrayWithOrder: 'The tray will be link to the order #{{lookupId}}.',
  writeDrugChanged: 'Indicate the change of the alternative on the file.',
  wrongUsernameOrPassword: 'Incorrect username or password. Please try again.',
  yes: 'Yes',
};

export default en;
