export interface OrganizationCreateFields extends Omit<Organization, 'id' | 'createdAt'> {
  name: string;
  prescriptionSoftware?: PrescriptionSoftwareFields[];
  status?: OrganizationStatus;
}

export interface Organization {
  id: string;
  createdAt: Date;
  name: string;
  prescriptionSoftware?: PrescriptionSoftwareFields[];
  status?: OrganizationStatus;
}

export interface PrescriptionSoftwareFields {
  name: PrescriptionSoftware;
  authUser?: string | undefined;
  authPassword?: string | undefined;
}

export interface OrganizationPublicFields extends Omit<Organization, 'prescriptionSoftware'> {
  prescriptionSoftware?: Omit<PrescriptionSoftwareFields, 'authPassword'>[];
}

export enum PrescriptionSoftware {
  None = 'None',
  TestDev = 'TestDev', // This is for prescription software developers intending to tests our API.
  Cubi = 'Cubi',
  Ubik = 'Ubik',
  RxPro = 'RxPro',
  PrioRx = 'PrioRx',
  ReflexRx = 'ReflexRx',
}

export enum OrganizationStatus {
  UNDER_MAINTENANCE = 'UNDER_MAINTENANCE',
  ACTIVE = 'ACTIVE',
}
