/* eslint-disable indent */
// eslint is disable because of a conflict with prettier
import React, { useEffect, useRef } from 'react';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Formik, FormikHelpers, ErrorMessage } from 'formik';
import { Modal, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { t } from 'i18next';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { LoadingButton } from 'components/shared/LoadingButton';
import { createOrganization, updateOrganization } from 'services/organizations/operations';
import { createMachine } from 'services/machines/operations';
import Types from 'services/organizations/types';
import './style.scss';
import {
  Organization,
  OrganizationStatus,
  PrescriptionSoftware,
  PrescriptionSoftwareFields,
} from './types';
import PrescriptionSoftwareForm from './PrescriptionSoftwareFormModal';

const validationSchema = yup.object().shape({
  organizationName: yup
    .string()
    .matches(/^[a-zA-Z0-9-]+$/, t('fieldOnlyAcceptAlphanumeric'))
    .required('organizationNameRequired'),

  status: yup.string().oneOf(Object.values(OrganizationStatus)),

  prescriptionSoftware: yup.array().of(
    yup.object().shape({
      name: yup.string().oneOf(Object.values(PrescriptionSoftware)),

      authUser: yup
        .string()
        .matches(/^[a-zA-Z0-9]+$/, t('fieldOnlyAcceptAlphanumeric'))
        .required(t('userNameRequired')),

      authPwd: yup
        .string()
        .matches(/^[a-zA-Z0-9-]+$/, t('fieldOnlyAcceptAlphanumeric'))
        .nullable()
        .notRequired(),
    }),
  ),
});

export interface FormValues {
  organizationName: string;
  status: OrganizationStatus;
  prescriptionSoftware: PrescriptionSoftwareFields[];
}

interface OrganizationFormModalProps {
  show: boolean;
  close: () => void;
  organization?: Organization;
}

const OrganizationFormModal = ({ show, close, organization }: OrganizationFormModalProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch<Dispatch<any>>();

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  const initialValues: FormValues = {
    organizationName: organization ? organization.name : '',
    status: organization?.status ? organization.status : OrganizationStatus.ACTIVE,
    prescriptionSoftware: organization?.prescriptionSoftware
      ? organization.prescriptionSoftware.map((ps) => ({
          name: ps.name,
          authUser: ps.authUser ?? undefined,
          authPassword: ps.authPassword ?? undefined,
        }))
      : [],
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    setSubmitting(true);

    const formattedPrescriptionSoftware = values.prescriptionSoftware.map((ps) => ({
      name: ps.name,
      authUser: ps.authUser ?? '',
      authPassword: ps.authPassword ?? '',
    }));

    if (organization) {
      try {
        const editedOrganization: Organization = {
          id: organization.id,
          name: values.organizationName,
          createdAt: organization.createdAt,
          status: values.status,
          prescriptionSoftware: formattedPrescriptionSoftware,
        };

        await updateOrganization(organization.id, editedOrganization)(dispatch);

        setSubmitting(false);
        close();
      } catch (err) {
        handleHttpResponseError(err, 'FAILED EDITING ORGANIZATION', setFieldError);
      }
    } else {
      try {
        const response = await createOrganization({
          name: values.organizationName,
          status: values.status,
          prescriptionSoftware: formattedPrescriptionSoftware,
        })(dispatch);

        if (response.type === Types.CREATE_ORGANIZATION_RESPONSE) {
          await createMachine({
            id: response.payload.organization.name,
            organizationId: response.payload.organization.id,
            name: values.organizationName,
          })(dispatch);
        }
        setSubmitting(false);
        close();
      } catch (err) {
        handleHttpResponseError(err, 'FAILED UPLOAD ORGANIZATION', setFieldError);
      }
    }
    setSubmitting(false);
  };

  return (
    <Modal show={show} onHide={close} className="organization-form-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {organization
            ? t('editOrganization', { organizationName: organization.name })
            : t('addOrganization')}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleBlur, handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="organizationName">
                <Form.Label>{t('name')}</Form.Label>
                <Form.Control
                  ref={inputRef}
                  defaultValue={initialValues.organizationName}
                  className={
                    errors.organizationName && touched.organizationName ? 'error-text' : ''
                  }
                  type="text"
                  name="organizationName"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue('organizationName', event.target.value);
                  }}
                />
                <ErrorMessage
                  name="organizationName"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>
              <Form.Group className="organization-status">
                <Form.Label>{t('status')}</Form.Label>
                <select
                  name="status"
                  defaultValue={
                    organization?.status ? organization?.status : OrganizationStatus.ACTIVE
                  }
                  onChange={(e) => setFieldValue('status', e.target.value as OrganizationStatus)}
                  className={
                    errors.status && touched.status ? 'custom-select error-select' : 'custom-select'
                  }
                >
                  {Object.values(OrganizationStatus).map((os) => (
                    <option key={os} value={os}>
                      {t(os)}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  name="status"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>
              <hr />
              <PrescriptionSoftwareForm
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                values={values}
              />
            </Modal.Body>
            <Modal.Footer>
              <LoadingButton
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('submit')}
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default OrganizationFormModal;
