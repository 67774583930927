import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { ErrorMessage, FieldArray, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { FormValues } from './OrganizationFormModal';
import { PrescriptionSoftware } from './types';

interface PrescriptionSoftwareFormProps {
  handleBlur: FormikProps<FormValues>['handleBlur'];
  setFieldValue: FormikProps<FormValues>['setFieldValue'];
  values: FormikProps<FormValues>['values'];
}

const PrescriptionSoftwareForm = ({
  handleBlur,
  setFieldValue,
  values,
}: PrescriptionSoftwareFormProps) => (
  <Form.Group>
    <h5>{t('prescriptionSoftware')}</h5>
    <FieldArray
      name="prescriptionSoftware"
      render={(arrayHelpers) => (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1%',
            }}
          >
            <Form.Label>{t('software')}</Form.Label>
            <Button
              onClick={() => arrayHelpers.push({ name: '', authUser: '', authPassword: '' })}
              variant="outline-secondary"
              size="sm"
            >
              <FontAwesomeIcon icon="plus" />
            </Button>
          </div>
          {values.prescriptionSoftware.map((software, index) => (
            <div key={index} style={{ marginBottom: '1%' }}>
              <Form.Group>
                <Form.Label>{t('software')}</Form.Label>
                <Form.Control
                  as="select"
                  name={`prescriptionSoftware[${index}].name`}
                  value={software.name}
                  onChange={(e) => {
                    const newSoftware = [...values.prescriptionSoftware];
                    newSoftware[index].name = e.target.value as PrescriptionSoftware;
                    setFieldValue('prescriptionSoftware', newSoftware);
                  }}
                  onBlur={handleBlur}
                >
                  {Object.values(PrescriptionSoftware).map((ps) => (
                    <option key={ps} value={ps}>
                      {ps}
                    </option>
                  ))}
                </Form.Control>
                <ErrorMessage
                  name={`prescriptionSoftware[${index}].name`}
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>{t('user')}</Form.Label>
                <Form.Control
                  type="text"
                  name={`prescriptionSoftware[${index}].authUser`}
                  value={software.authUser}
                  onChange={(e) => {
                    const newSoftware = [...values.prescriptionSoftware];
                    newSoftware[index].authUser = e.target.value;
                    setFieldValue('prescriptionSoftware', newSoftware);
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name={`prescriptionSoftware[${index}].authUser`}
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>{t('password')}</Form.Label>
                <Form.Control
                  type="password"
                  name={`prescriptionSoftware[${index}].authPassword`}
                  value={software.authPassword}
                  onChange={(e) => {
                    const newSoftware = [...values.prescriptionSoftware];
                    newSoftware[index].authPassword = e.target.value;
                    setFieldValue('prescriptionSoftware', newSoftware);
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name={`prescriptionSoftware[${index}].authPassword`}
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>

              <Button
                variant="outline-danger"
                style={{ marginLeft: '1%' }}
                onClick={() => arrayHelpers.remove(index)}
                size="sm"
              >
                <FontAwesomeIcon icon="trash" />
              </Button>
            </div>
          ))}
        </div>
      )}
    />
  </Form.Group>
);
export default PrescriptionSoftwareForm;
