import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { t } from 'i18next';
import ResourcesHandler from 'components/ResourcesHandler';
import { fetchOrganizations } from 'services/organizations/operations';
import { fetchUsers } from 'services/users/operations';
import { Button, Form } from 'react-bootstrap';
import { getPillDistributionReport } from 'services/reports/endpoints';
import { PrescriptionSoftware } from 'components/organizations/types';

const ReportPage = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const organizations = useSelector((state: RootState) => state.organizations);
  const users = useSelector((state: RootState) => state.users);
  const user = useSelector((state: RootState) => state.login);
  const isAdmin = user.organizationId === '*';
  const cubiUsers = organizations
    .find((org) => org.id === user.organizationId)
    ?.prescriptionSoftware?.filter((pS) => pS.name === PrescriptionSoftware.Cubi);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState<string>(
    isAdmin ? '' : user.organizationId!,
  );
  const [reportType, setReportType] = useState('pillDistributionReport');
  const [selectedCubiUser, setSelectedCubiUser] = useState('');
  const [orgError, setOrgError] = useState(false);
  const [dateErrors, setDateErrors] = useState<{ start?: string; end?: string }>({});

  const handleGenerate = async (e: React.FormEvent) => {
    e.preventDefault();

    let hasError = false;
    const newDateErrors: { start?: string; end?: string } = {};
    setOrgError(false);
    setDateErrors({});

    if (isAdmin && !selectedOrganization) {
      setOrgError(true);
      hasError = true;
    }

    if (!startDate) {
      newDateErrors.start = t('startDateRequired');
      hasError = true;
    }
    if (!endDate) {
      newDateErrors.end = t('endDateRequired');
      hasError = true;
    } else if (startDate && endDate && startDate > endDate) {
      newDateErrors.start = t('startDateAfterEnd');
      newDateErrors.end = t('endDateBeforeStart');
      hasError = true;
    }

    if (hasError) {
      setDateErrors(newDateErrors);
      return;
    }

    try {
      const response = await getPillDistributionReport(
        selectedOrganization,
        startDate,
        endDate,
        selectedCubiUser || undefined,
      );

      const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${t('pillDistributionReport')}_${startDate}-${endDate}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error generating report:', error);
    }
  };

  const getPage = () => (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '20px',
        }}
      >
        <h1>{t('reports')}</h1>

        {isAdmin && (
          <Form.Group controlId="organizationSelect" style={{ width: 300 }}>
            <Form.Label>{t('organization')}</Form.Label>
            <Form.Control
              as="select"
              value={selectedOrganization}
              onChange={(e) => setSelectedOrganization(e.target.value)}
              isInvalid={orgError}
            >
              <option value="">{t('selectOrganization')}</option>
              {organizations.map((org: any) => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </Form.Control>
            <div style={{ height: 20 }}>
              {orgError && <Form.Text className="text-danger">{t('selectOrganization')}</Form.Text>}
            </div>
          </Form.Group>
        )}
      </div>

      <hr />

      <Form onSubmit={handleGenerate} style={{ marginLeft: 20 }}>
        <Form.Group style={{ marginBottom: 20 }}>
          <Form.Label>{t('reports')}</Form.Label>
          <div style={{ display: 'flex', gap: 20 }}>
            <Form.Control
              as="select"
              id="reportType"
              style={{ width: 300 }}
              value={reportType}
              onChange={(e) => {
                setReportType(e.target.value);
                setSelectedCubiUser('');
              }}
            >
              <option value="pillDistributionReport">{t('pillDistributionReport')}</option>
            </Form.Control>

            {reportType === 'pillDistributionReport' && cubiUsers && cubiUsers.length > 1 && (
              <Form.Control
                as="select"
                id="cubiUserSelect"
                style={{ width: 300 }}
                value={selectedCubiUser}
                onChange={(e) => setSelectedCubiUser(e.target.value)}
              >
                <option value="">{t('filterByCubiUser')}</option>
                {cubiUsers.map((user) => (
                  <option key={user.authUser} value={user.authUser}>
                    {user.authUser}
                  </option>
                ))}
              </Form.Control>
            )}
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>Période:</Form.Label>
          <div style={{ display: 'flex', gap: 20 }}>
            <div>
              <Form.Control
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                isInvalid={!!dateErrors.start}
                style={{ width: 160 }}
              />
              {dateErrors.start && (
                <Form.Text className="text-danger">{dateErrors.start}</Form.Text>
              )}
            </div>

            <div>
              <Form.Control
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                isInvalid={!!dateErrors.end}
                style={{ width: 160 }}
              />
              {dateErrors.end && <Form.Text className="text-danger">{dateErrors.end}</Form.Text>}
            </div>
          </div>
        </Form.Group>

        <Button type="submit">{t('generate')}</Button>
      </Form>
    </div>
  );

  return (
    <ResourcesHandler
      resources={[organizations, users]}
      resourceFetchers={[() => dispatch(fetchOrganizations()), () => dispatch(fetchUsers())]}
      getContents={getPage}
    />
  );
};

export default ReportPage;
